import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Row, Skeleton } from 'antd';

type TopDescriptionsProps = {
  loading?: boolean;
  children: {
    label: React.ReactNode;
    value: React.ReactNode;
  }[];
};

const TopDescriptions: React.FC<TopDescriptionsProps> = ({ loading, children }) => (
  <Skeleton loading={loading} active={loading}>
    <Row gutter={20}>
      {children.map(({ label, value }, index) => (
        <Col key={index} lg={8} md={12} sm={24}>
          <b>{label}</b>: {value || <FormattedMessage id="not_applicable_short" defaultMessage="N/A" />}
        </Col>
      ))}
    </Row>
  </Skeleton>
);

export default TopDescriptions;
