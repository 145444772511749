import { gql, useQuery } from '@apollo/client';
import React from 'react';
import { useRouteMatch } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { isActualNumber } from '../../util/isActualNumber';
import TopDescriptions from '../../components/TopDescriptions';
import { SourceQuery, SourceQueryVariables } from './__generated__/SourceQuery';
import { TimeAgoLabel } from '../../components/TimeAgoLabel';

const DcrSourceDetail: React.FC = () => {
  const match = useRouteMatch<{ id: string }>();
  const sourceId = match.params.id;

  if (!isActualNumber(sourceId)) {
    return <span>Hello</span>;
  }

  const { data, loading } = useQuery<SourceQuery, SourceQueryVariables>(DATA_QUERY, { variables: { sourceId } });

  return (
    <div className="dcr-source-detail-container">
      <TopDescriptions loading={loading}>
        {[
          {
            label: <FormattedMessage id="id" defaultMessage="Id" />,
            value: data?.source?.id,
          },
          {
            label: <FormattedMessage id="name" defaultMessage="Name" />,
            value: data?.source?.name,
          },
          {
            label: <FormattedMessage id="account" defaultMessage="Account" />,
            value: <NavLink to={`/account/${data?.source?.account?.id}`}>{data?.source?.account?.name}</NavLink>,
          },
          {
            label: 'Amount of DCRs',
            value: data?.source?.dcrGroups?.totalCount,
          },
          {
            label: <FormattedMessage id="created" defaultMessage="Created" />,
            value: <TimeAgoLabel time={data?.source?.ct} />,
          },
        ]}
      </TopDescriptions>
    </div>
  );
};

const DATA_QUERY = gql`
  query SourceQuery($sourceId: Int!) {
    source(id: $sourceId) {
      id
      name
      ct
      account {
        id
        name
      }
      dcrGroups {
        hash
        totalCount
      }
    }
  }
`;

export default DcrSourceDetail;
