/* eslint-disable @typescript-eslint/no-explicit-any */
import { isNaN, isArray, isNil, isNumber, toNumber } from 'lodash';

/**
 * Checks whether a [value] is a number.
 * @param value
 * @returns {boolean}
 *
 * @example
 *   isActualNumber(25)     // true
 *   isActualNumber('25')   // true
 *   isActualNumber(NaN)    // false
 *   isActualNumber('abc')  // false
 *   isActualNumber(null)   // false
 *   isActualNumber()       // false
 */
export const isActualNumber = (value?: any): value is number => !isNil(value)
  && isNumber(toNumber(value))
  && !isNaN(toNumber(value))
  && !isArray(value);
