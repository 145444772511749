import { gql, useQuery } from '@apollo/client';
import React from 'react';
import { useRouteMatch } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { isActualNumber } from '../../util/isActualNumber';
import { DcrAccountQuery, DcrAccountQueryVariables } from './__generated__/DcrAccountQuery';
import TopDescriptions from '../../components/TopDescriptions';

const DcrAccountDetail: React.FC = () => {
  const match = useRouteMatch<{ id: string }>();
  const accountId = match.params.id;

  if (!isActualNumber(accountId)) {
    return <span>Hello</span>;
  }

  const { data, loading } = useQuery<DcrAccountQuery, DcrAccountQueryVariables>(DATA_QUERY, { variables: { accountId } });

  return (
    <div className="dcr-account-detail-container">
      <TopDescriptions loading={loading}>
        {[
          {
            label: <FormattedMessage id="id" defaultMessage="ID" />,
            value: data?.account?.id,
          },
          {
            label: <FormattedMessage id="name" defaultMessage="Name" />,
            value: data?.account?.name,
          },
          {
            label: 'Amount of sources',
            value: data?.account?.sources.totalCount,
          },
          {
            label: 'Amount of DCRs',
            value: data?.account?.dcrGroups.totalCount,
          },
        ]}
      </TopDescriptions>
    </div>
  );
};

const DATA_QUERY = gql`
  query DcrAccountQuery($accountId: Int!) {
    account(id: $accountId) {
      id
      name
      dcrGroups {
        hash
        totalCount
      }
      sources {
        hash
        totalCount
      }
    }
  }
`;

export default DcrAccountDetail;
